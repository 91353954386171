<template>
  <div class="guide">
    <div class="textBox">
        <div v-for="guide in guideList" :key="guide.icon" class="pBox" :class="{titleBox:guide.type==='title'}">
            <!-- 展示图标 -->
           <div class="iconBox">
            <!-- 暂时使用图片和svg，等图标库能使用了统一使用svg -->
             <svg  class="icon" aria-hidden="true">
                <use :href="guide.icon"></use>
            </svg>
           </div>
            <!-- 展示文本 -->
            <div> {{guide.text}}</div>
        </div>
        <!-- 我知道了按钮 -->
        <div class="bottonBox">
            <span @click="closeDialog">
               {{ $t("c1000") }}
            </span>
        </div>
    </div>
  </div>
</template>

<script>
/**
 * 新功能引导弹窗：用户首次进入引导提示，仅展示一次
 */
export default {
    data(){
        return {
            // 展示内容
            guideList:[{
                    type:'title',
                    icon: '#icon-new',
                    // icon: require('../img/new.svg'),
                    text:this.$i18n.t("c1001")
                    // text:'邀请您体验钉钉集成后台系统设置'
                },{
                    icon:'#icon-yindao-1',
                    text:this.$i18n.t("c1002")
                    // text:'CloudCC钉应用是基于CloudCC与钉钉平台打造的营销销售服务一体化解决方案。'
                },{
                    icon:'#icon-yindao-2',
                    text:this.$i18n.t("c1003")
                    // text:'CC钉通过连接钉钉的协同能力，帮助您更完整的进行私域流量运营，追踪、管理客户关系全生命周期，提升企业运营效率和盈利能力。'
                },{
                    icon:'#icon-yindao-3',
                    text:this.$i18n.t("c1004")
                    // text:'CC钉继承CloudCC平台强大的定制化能力，可以针对您个性化的业务需求，快速、灵活的配置扩展。'
                }]
          
        }
    },
    methods:{
        /**
         * 通知父组件关闭弹窗
         */
        closeDialog(){
            this.$emit('closeDialog')
        }
    }
}
</script>

<style lang="scss" scoped>
.guide{
    width: 100%;
    min-height: 586px;
    background:  url('../img/guide.svg') no-repeat; 
    background-size:100%;
    padding: 293px 80px 27px 80px;
    .textBox{
        .pBox{
            display: flex;
            margin-bottom: 20px;
            font-size: 14px;
            .icon{
                width: 16px;
                height: 16px;
                margin: 0 15px;
            }
        }
        .titleBox{
            margin-bottom: 40px;
            font-size: 16px;
            font-weight: bold;
            .icon{
                width: 24px;
                height: 24px;
                margin: 0 11px 0 0;
            }
        }
        .bottonBox{
            padding-top: 36px;
            text-align: center;
            span{
                font-size: 16px;
                cursor: pointer;
                color: #2D6CFC;
            }
        }
    }
}
</style>