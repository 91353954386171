<template>
  <el-card class="card_left" v-loading="leftCardLoading">
    <div class="inner_box">
      <div class="el_tree_search_wrapper">
        <el-input
          :placeholder="$t('label.view.edit.search.describe')"
          v-model="filterText"
          @keyup.enter.native="filter"
        />
      </div>
      <el-tree
        class="tree"
        :data="menuList"
        :props="defaultProps"
        :filter-node-method="filterNode"
        ref="tree"
        highlight-current
        node-key="id"
        :default-expanded-keys="default_expanded_keys"
        :default-checked-keys="default_checked_keys"
        :current-node-key="current_node_key"
        :render-after-expand="false"
        @current-change="currentChange"
        icon-class="el-icon-arrow-right"
        :indent="30"
      >
        <span class="custom-tree-node" slot-scope="{ node }">
          <span class="custom_label">{{ node.label }}</span>
        </span>
      </el-tree>
    </div>
  </el-card>
</template>

<script>
// import menuList from "./js/menuList";
import $i18n from "@/utils/i18n"; //多语言
// eslint-disable-next-line no-unused-vars
import * as apis from "./api.js";
export default {
  created() {
    this.getSetupList();
  },
  watch: {
    filterText(val) {
      if (!val) {
        setTimeout(() => {
          this.$refs.tree.filter(val);
        }, 30);
      }
    },
  },
  methods: {
    filter() {
      this.$refs.tree.filter(this.filterText);
    },
    filterNode(value, data, node) {
      //若是共有三级菜单
      if (!value) return true;
      let if_one = data.label.indexOf(value) !== -1;
      let if_two =
        node.parent &&
        node.parent.data &&
        node.parent.data.label &&
        node.parent.data.label.indexOf(value) !== -1;
      let if_three =
        node.parent &&
        node.parent.parent &&
        node.parent.parent.data &&
        node.parent.parent.data.label &&
        node.parent.parent.data.label.indexOf(value) !== -1;

      let if_4 =
        node.parent &&
        node.parent.parent &&
        node.parent.parent.parent &&
        node.parent.parent.parent.data &&
        node.parent.parent.parent.data.label &&
        node.parent.parent.parent.data.label.indexOf(value) !== -1;

      let result_one = false;
      let result_two = false;
      let result_three = false;
      let result_4 = false;
      if (node.level === 1) {
        result_one = if_one;
      } else if (node.level === 2) {
        result_two = if_one || if_two;
      } else if (node.level === 3) {
        result_three = if_one || if_two || if_three;
      } else if (node.level === 4) {
        result_4 = if_one || if_two || if_three || if_4;
      }
      return result_one || result_two || result_three || result_4;
    },
    // 菜单切换
    currentChange(current) {
      let { id, label, path, jumpPath } = current;
      this.current_node_key = id;
      this.current_node_path = path;
      this.big_title = label; //设置标题
      this.$emit("changeBigTile", this.big_title);
      if (path) {
        this.$router.push(`/systemSettings/${path}`);
      }
      if (jumpPath) {
        window.open(jumpPath);
      }
    },
    // 嗯
    treeStyleAlter() {
      // 定义某一些一级菜单非法点击
      let nodeList = document.querySelectorAll(".el-tree-node");
      //
      nodeList.forEach((item) => {
        //
        if (item.getAttribute("aria-disabled")) {
          item.classList.add("tree_left_offset");
          item.setAttribute("style", " pointer-events:none;cursor: default;");
        }
      });
      // 自定义一级菜单向左侧icon对其，会影响到子集菜单，so 使用indent 属性减去干扰
      let isleaf = document.querySelectorAll(".el-tree-node .is-focusable");
      isleaf.forEach((item) => {
        item.style = "margin-left: -17px;";
      });
    },
    async getSetupList() {
      let { data } = await apis.getSetupList();
      this.menuList = data;
      //

      // 遍历目录，如果企业微信联系人通讯录没开启，则不显示角色绑定和用户绑定目录
      this.leftCardLoading = false;
      this.$nextTick(() => {
        this.treeStyleAlter();
      });
    },
  },
  mounted() {
    this.$bus.$on("refreshgetSetupList", () => {
      this.getSetupList();
    });
  },
  data() {
    return {
      jumpPath: `https://testuat.cloudcc.com/uiSetup.action?binding=${this.$CCDK.CCToken.getToken()}`,
      leftCardLoading: true,
      /**
       * 右侧面板标题
       *    ↓
       */
      // mini_title: $i18n.t("label.emailsync.form.seversetting"),

      big_title: $i18n.t("vue_label_systemSettings_setHomePage"),
      /**
       *    ↑
       * 右侧面板标题
       */
      filterText: "", //筛选字段
      default_expanded_keys: [0], //默认展开节点
      default_checked_keys: [0], //默认选中的节点
      current_node_key: 0, //当前选中节点
      // current_node_label: "个人信息", //当前选中节点文本（非自带）

      current_node_path: "profileInfo", //当前选中节点路由（非自带）
      /**
       * 所有菜单，即没有 path 也没有 jumpPath 则是1级，或者可以理解为还有子集菜单（则是点击展开，而不是跳转）
       * id               需要随机，并且 children 的子集id不能和父级id 一致
       * rootStatus:1     【是否顶级菜单 ，暂时不要此参数】
       * label            后面是菜单名称， $i18n.t("vue_label_systemSettings_setHomePage") 中字符串是 多语言label
       * disabled: true  （顶级菜单）禁用点击的菜单（非1级菜单）
       * path：           跳转到lighting的路由（跳转到老系统不需要返回此参数）
       * jumpPath         跳转到老系统的网址（不跳转到老系统则不要返回此参数）
       */
      menuList: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  computed: {
    menuListFix() {
      let { menuList } = this;
      let id = 0;
      menuList.forEach((item) => {
        item.id = id++;
        if (item.children) {
          item.children.forEach((itemx) => {
            itemx.id = id++;
          });
        }
      });
      return menuList;
    },
  },
};
</script>

<style  lang="scss" scoped>
@import "../../index.scss";
</style>
<style lang="scss">
// .tree_left_offset {
//   background-color: #fff;
//   margin-left: -15px;
// }
// .custom_label {
//  text-decoration: underline;
// }
</style>
<style>
.inner_box .is-current > .el-tree-node__content {
  border-left: 2px solid #2e739c;
}
.el-tree-node__content {
  border-left: 2px solid #fff;
}
.inner_box .is-current > .el-tree-node__content .custom_label {
  text-decoration: underline;
}
</style>