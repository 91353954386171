// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/guide.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".guide[data-v-c8857746]{width:100%;min-height:586px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100%;padding:293px 80px 27px 80px}.guide .textBox .pBox[data-v-c8857746]{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:20px;font-size:14px}.guide .textBox .pBox .icon[data-v-c8857746]{width:16px;height:16px;margin:0 15px}.guide .textBox .titleBox[data-v-c8857746]{margin-bottom:40px;font-size:16px;font-weight:700}.guide .textBox .titleBox .icon[data-v-c8857746]{width:24px;height:24px;margin:0 11px 0 0}.guide .textBox .bottonBox[data-v-c8857746]{padding-top:36px;text-align:center}.guide .textBox .bottonBox span[data-v-c8857746]{font-size:16px;cursor:pointer;color:#2d6cfc}", ""]);
// Exports
module.exports = exports;
