<template>
  <!-- 个人设置菜单页 -->
  <div class="profile_setting">
    <!-- 左侧面板 -->
    <SettingLeftMenu @changeBigTile="changeBigTile" />
    <!-- 右侧 -->
    <div class="box_right" ref="DynamicPart">
      <!-- 顶部面板顶部 -->
      <el-card class="card_right_top">
        <div class="left_box">
          <svg
            aria-hidden="true"
            style="width: 40px; height: 40px"
          >
            <use xlink:href="#icon-profile_setting_icon"></use>
          </svg>
        </div>
        <div class="right_box">
          <div class="right_box_min_title">
            {{ $i18n.t("label.emailsync.form.seversetting") }}
          </div>
          <div class="right_box_big_title">
            {{ bigTile }}
          </div>
        </div>
      </el-card>
      <!-- 底部面板路由 -->
      <div>
        <div>
          <el-card class="card_router_view">
            <router-view />
          </el-card>
        </div>
      </div>
    </div>
    <!-- 引导页弹窗 -->
    <el-dialog title="" :visible.sync="guide" width="56%" top="10vh" :close-on-click-modal="false" custom-class="guideBox">
      <Guide @closeDialog="closeDialog"/>
    </el-dialog>
    
  </div>
</template>

<script>
/**
 * 系统设置
 */
import SettingLeftMenu from "./components/settingLeftMenu/index";
import Guide from "./components/guide.vue";
import * as systemSettingsApi from "./systemSettingsApi.js";
export default {
  data() {
    return {
      // 引导弹窗
      guide: false,
      bigTile: this.$i18n.t("vue_label_systemSettings_setHomePage"), // 右侧标题，标识当前菜单位置
      // leftCardLoading: true,
      // filterText: "", //筛选字段
      // default_expanded_keys: [0], //默认展开节点
      // default_checked_keys: [0], //默认选中的节点
      // current_node_key: 0, //当前选中节点
      // current_node_label: "个人信息", //当前选中节点文本（非自带）
      // current_node_path: "profileInfo", //当前选中节点路由（非自带）
      /**
       * 所有菜单，即没有 path 也没有 jumpPath 则是1级，或者可以理解为还有子集菜单（则是点击展开，而不是跳转）
       * id               需要随机，并且 children 的子集id不能和父级id 一致
       * rootStatus:1     【是否顶级菜单 ，暂时不要此参数】
       * label            后面是菜单名称， $i18n.t("vue_label_systemSettings_setHomePage") 中字符串是 多语言label
       * disabled: true  （顶级菜单）禁用点击的菜单（非1级菜单）
       * path：           跳转到lighting的路由（跳转到老系统不需要返回此参数）
       * jumpPath         跳转到老系统的网址（不跳转到老系统则不要返回此参数）
       */
      // menuList: [],
      // defaultProps: {
      //   children: "children",
      //   label: "label",
      // },
    };
  },
  components: {
    SettingLeftMenu,
    Guide,
  },
  created() {
    // 查询当前用户是否需要展示引导页
    this.isShowGuidePages()
  },
  watch: {},
  mounted() {
    this.listenerFunction();
  },
  beforeDestroy() {
    //移除滚动条事件
    this.beforeDestroy();
  },
  methods: {
    /**
     * 查询当前用户是否需要展示引导页
     */
    isShowGuidePages(){
      systemSettingsApi.isShowGuidePages().then(res=>{
        if(res.data){
          // ccding:ccding引导页
          this.guide=res.data.ccding==='true'
          // 暂时都写true
          // this.guide=true
        }
      })
    },
    /**
     * Guide组件调用，关闭弹窗
     */
    closeDialog(){
      this.guide=false
    },
    //监听浏览器滚动
    listenerFunction() {
      this.$refs.DynamicPart.addEventListener(
        "scroll",
        this.handleScroll,
        true
      );
    },
    handleScroll(e) {
      //如果当前滚动条为右侧大盒子则执行吸顶方法
      let scrolltop = e.target.scrollTop;
      if (e.target.classList[1] == "card_router_view") {
        this.$Bus.$emit("Ceiling-height", scrolltop);
      } else {
        return;
      }
    },
    beforeDestroy() {
      document.removeEventListener("scroll", this.listenerFunction);
    },
    // 切换菜单时修改右侧标题
    changeBigTile(title) {
      this.bigTile = title;
    },
  },
};
</script>

<style  lang="scss" scoped>
@import "./index.scss";
::v-deep .guideBox{
  border-radius: 12px;
  min-width: 600px;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
  }
}
</style>
<style>
.inner_box .is-current > .el-tree-node__content .custom_label {
  text-decoration: underline;
}
</style>